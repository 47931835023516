@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.row {
    --bs-gutter-x: 1rem;
}
.row.login-row{
    --bs-gutter-x: 0rem;
}
.loader-component {
    background-color: rgb(248, 248, 248, 60%);
    position: fixed;
    z-index: 1000000;
}

.dark-layout {
    .loader-component {
        background-color: rgb(22, 29, 49, 60%);
    }
}
.dark-layout {
    .card .card-title {
        font-size: 1.714rem;
        color: #ffffff;
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}
.dragging {
    opacity: 0.5;
}

.inputStyle {
    border: 1.8px solid #d8d6de !important;
    border-radius: 0.357rem !important;
    height: 50px !important;
    width: 75% !important;
    text-align: center;
    margin: auto;
}
.dark-layout .inputStyle {
    // background-color: $primary !important;
    border: 1px solid #8f8f90 !important;
}

.inputStyle:active {
    border-color: #147dce !important;
}

.inputStyle:hover {
    border-color: #147dce !important;
}

.inputStyle:focus {
    border-color: #147dce !important;
}

.inputStyle:visited {
    border-color: #147dce !important;
    outline: none;
}

.inputStyle:focus-within {
    border-color: #147dce !important;
}

.inputStyle:focus-visible {
    border-color: #147dce !important;
}

.inputStyle:target {
    border-color: #147dce !important;
}

/* Add this to your stylesheet */
.emergency-card-hover {
    transition: transform 0.3s ease; /* Add a smooth transition for the transform effect */
}

.emergency-card-hover:hover {
    transform: translateY(-5px);
}

/* Optional: Add a shadow to create a lifting effect */
.emergency-card-hover:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.dark-layout .custom-a-class a {
    color: white;
    text-decoration: 'none';
}

.custom-a-class a {
    color: inherit;
    text-decoration: 'none';
}

//** priority indicatore color for dropdown
.indicator {
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 10px;
}

.high-indicator {
    background-color: #f62b2b;
}

.low-indicator {
    background-color: #28a828;
}

.medium-indicator {
    background-color: #ff7800;
}
.card .card {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
.nodata_img {
    width: 70%;
    height: 300px;
}
.nodata_img_sos {
    width: 100%;
    height: 400px;
}
.card-hover1:hover {
    box-shadow: 0px 0px 15px #839a9c;
    // transition: box-shadow 0.3s;
}
// ** over all project applay
.card .card-title {
    font-size: 1.714rem;
    // color: #0a0a0a;
}
.form-label {
    // color: #1d1d1d;
    // font-weight: 500;
    font-size: 1rem;
    font-weight: 500;
    color: #0a0a0a;
}
.form-control {
    color: #0a0a0a;
    font-weight: 500;
    font-size: 1.1rem;
}
.nav-tabs .nav-link {
    color: #0a0a0a;
}
//wallet
.money_card {
    height: auto;
    width: 80%;
}
.balance_text {
    color: #283046;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 20px;
}
.amount-text {
    color: #283046;
    font-weight: 600;
    font-size: 25px;
}
.balance_text_app_user {
    color: #283046;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 18px;
}
.amount_text_app_user {
    color: #283046;
    font-weight: 600;
    font-size: 25px;
}
.wallet-right-border {
    border-right: 1px solid #ebe9f1;
}

.obd-color {
    background-color: #efefef;
}

.dark-layout .obd-color {
    background-color: #283046;
}

//** obd detail card responsive css'
.main-common-div {
    position: absolute;
    transform: translate(-50%, -50%);
    color: gray;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}
.car-div-1 {
    top: 20%;
    left: 20%;
}
.car-div-2 {
    top: 20%;
    left: 80%;
}
.car-div-3 {
    top: 66%;
    left: 20%;
}
.car-div-4 {
    top: 66%;
    left: 80%;
}
.car-div-5 {
    top: 87%;
    left: 50%;
}
//** all card common css
.info-card-first-letter {
    align-items: 'flex-start';
    width: 70px;
    height: 70px;
    border-radius: 5px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: #0a0a0a;
}

// button base open new card detail in map
.card-open {
    width: 100%;
    // transition: width 0.8s ease-in-out;
    transition: width 1.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.card-closed {
    width: 100%;
    // transition: width 0.8s ease-in-out;
    transition: width 1.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.nav-pills .nav_pill_text_color .nav-link {
    color: black;
}
.nav-pills .nav_pill_text_color .nav-link.active {
    color: white;
}

// *** swal dark mode text message color
.dark-layout .dark-mode-title {
    color: #000; // Set the desired text color for dark mode
}

//** login swiper 
.swiper {
    width: 100%;
    height: 700px;
  }
  
.swiper-slide {
    text-align: center;
    font-size: 18px;
   
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
.login_swiper img{
    width: 100%;
    height: 600px;
}
.login_swiper .swiper-pagination-bullet-active{
    background-color: #2a1818;
    width: 10px;
    height: 10px;
}
.login_swiper .swiper-pagination-bullet {
    background-color: #ff0000; 
    width: 10px;
    height: 10px;
}
.login_swiper .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    height: 5px;
}


/* Optional, different gutter size on mobile */
@media (max-width: 800px) {
    .my-masonry-grid {
        margin-left: -15px; /* gutter size offset */
    }
    .my-masonry-grid_column {
        padding-left: 15px; /* gutter size offset */
    }
    .my-masonry-grid_column > div {
        margin-bottom: 15px; /* space between items */
    }
}

//wallet
@media (max-width: 767px) {    
    .wallet-right-border {
        border: 0px;
    }
}
@media (max-width: 475px) {
    .sm-custom{
        margin: auto;
        margin-bottom: 0;
    }
    .car-custom-padding {
        padding-top: 50px;
    }
    .car-div-1 {
        top: 5%;
        left: 17%;
    }
    .car-div-2 {
        top: 5%;
        left: 83%;
    }
    .car-div-3 {
        top: 63%;
        left: 16%;
    }
    .car-div-4 {
        top: 63%;
        left: 83%;
    }
    .car-div-5 {
        top: 102%;
        left: 50%;
        color: black;
        font-size: 33px;
    }
}
@media (min-width: 476px) and (max-width: 768px) {
    .car-custom-padding {
        padding-top: 50px;
    }
    .car-div-1 {
        top: 5%;
        left: 17%;
    }
    .car-div-2 {
        top: 5%;
        left: 83%;
    }
    .car-div-3 {
        top: 63%;
        left: 16%;
    }
    .car-div-4 {
        top: 63%;
        left: 83%;
    }
    .car-div-5 {
        top: 102%;
        left: 50%;
        color: black;
        font-size: 33px;
    }
}
@media (min-width: 769px) and (max-width: 991px) {
    .car-custom-padding {
        padding-top: 50px;
    }
    .car-div-5 {
        top: 102%;
        left: 50%;
        color: black;
        font-size: 33px;
    }
}
@media (min-width: 992px) and (max-width: 1400px) {
    .car-div-3 {
        top: 63%;
        left: 12%;
    }
    .car-div-4 {
        top: 63%;
        left: 85%;
    }
    .car-div-5 {
        top: 80%;
        left: 50%;
        
    }
}

.modal-xl2 {
    --bs-modal-width: 65%;
}
